<template>
    <div class="section onboarding">
        <center>
            <div class="join-wrapper">
                <svg viewBox="0 0 877 499" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="join-match" @click="connectMetamask()" style="cursor: pointer">
                    <g id="Group 296">
                    <g id="Group 295">
                    <g id="Group 292">
                    <path id="Vector" d="M28.2487 270.344V252.333V160.439H26.1359V254.45L17.5789 263.021V281.434L28.2487 270.746V270.344Z" fill="url(#paint0_linear_467_1287)"/>
                    <path id="Vector_2" d="M28.2487 413.561V321.667V303.656V303.254L17.5789 292.566V310.979L26.1359 319.551V413.561H28.2487Z" fill="url(#paint1_linear_467_1287)"/>
                    <path id="Vector_3" d="M859.421 263.021L850.864 254.45V160.439H848.751V252.333V270.344V270.746L859.421 281.434V263.021Z" fill="url(#paint2_linear_467_1287)"/>
                    <path id="Vector_4" d="M859.421 310.979V292.566L848.751 303.254V303.656V321.667V413.561H850.864V319.551L859.421 310.979Z" fill="url(#paint3_linear_467_1287)"/>
                    <path id="Vector_5" d="M4.22569 224.312L17.3253 211.191L17.262 205.265L0 222.556V350.238L18.5085 368.778V362.81L4.22569 348.503V224.312Z" fill="url(#paint4_linear_467_1287)"/>
                    <path id="Vector_6" d="M859.675 211.19L872.774 224.312V348.503L858.492 362.809L858.449 368.841L877 350.238V222.556L859.696 205.222L859.675 211.19Z" fill="url(#paint5_linear_467_1287)"/>
                    </g>
                    </g>
                    </g>
                    <g id="Group 364">
                    <g id="Mask Group">
                    <mask id="mask0_467_1287" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="28" y="77" width="820" height="419">
                    <path id="Vector_7" d="M718.161 77L702.894 92.2652H174.288L159.043 77H48.5454V90.6394L28 111.183V461.585L48.5454 482.128V495.768H158.79L173.57 480.988H701.691L716.725 496H827.455V482.128L848 461.585V111.183L827.455 90.6394V77H718.161Z" fill="#40BFFF"/>
                    </mask>
                    <g mask="url(#mask0_467_1287)">
                    <g id="battle-background 1">
                    <rect x="-50" y="37" width="976" height="499" fill="url(#pattern-join-bg)"/>
                    <rect x="-50" y="37" width="976" height="499" fill="black" fill-opacity="0.3"/>
                    </g>
                    </g>
                    </g>
                    <path id="Vector_8" d="M47.8137 75L26.136 96.7143V135.423H28.2488V111.36L48.8068 90.7672V77.1164H159.372L174.627 92.3968H703.535L717.882 78.0476L718.832 77.1164H828.193V90.7672L848.751 111.381V135.423H850.864V96.7143L829.186 75H47.8137ZM701.803 90.164H176.38L165.351 77.1164H712.832L701.803 90.164Z" fill="url(#paint6_linear_467_1287)"/>
                    <path id="Vector_9" d="M829.186 498.979L850.864 477.264V438.577H848.751V462.598L828.193 483.211V496.862H717.607L702.352 481.582H173.443L159.055 495.931L158.146 496.862H48.7644V483.211L28.2065 462.598V438.577H26.0936V477.264L47.7714 498.979H829.186ZM164.147 496.862L175.197 485.815H700.598L711.649 496.862H164.147Z" fill="url(#paint7_linear_467_1287)"/>
                    <g id="Group 293">
                    <path id="Vector_10" d="M597 87H593V80H596.993L597 87Z" fill="#FBC115"/>
                    <path id="Vector_11" d="M603 87H600V80H602.995L603 87Z" fill="#FBC115" fill-opacity="0.9"/>
                    <path id="Vector_12" d="M610 87H606V80H609.993L610 87Z" fill="#FBC115" fill-opacity="0.8"/>
                    <path id="Vector_13" d="M616 87H613V80H615.995L616 87Z" fill="#FBC115" fill-opacity="0.7"/>
                    <path id="Vector_14" d="M623 87H619V80H622.993L623 87Z" fill="#FBC115" fill-opacity="0.6"/>
                    <path id="Vector_15" d="M629 87H626V80H628.995L629 87Z" fill="#FBC115" fill-opacity="0.5"/>
                    <path id="Vector_16" d="M636 87H632V80H635.993L636 87Z" fill="#FBC115" fill-opacity="0.4"/>
                    <path id="Vector_17" d="M642 87H639V80H641.995L642 87Z" fill="#FBC115" fill-opacity="0.3"/>
                    <path id="Vector_18" d="M649 87H645V80H648.993L649 87Z" fill="#FBC115" fill-opacity="0.2"/>
                    <path id="Vector_19" d="M655 87H652V80H654.995L655 87Z" fill="#FBC115" fill-opacity="0.1"/>
                    </g>
                    <g id="Group 294">
                    <path id="Vector_20" d="M281 80L285 80L285 87L281.007 87L281 80Z" fill="#FBC115"/>
                    <path id="Vector_21" d="M275 80L278 80L278 87L275.005 87L275 80Z" fill="#FBC115" fill-opacity="0.9"/>
                    <path id="Vector_22" d="M268 80L272 80L272 87L268.007 87L268 80Z" fill="#FBC115" fill-opacity="0.8"/>
                    <path id="Vector_23" d="M262 80L265 80L265 87L262.005 87L262 80Z" fill="#FBC115" fill-opacity="0.7"/>
                    <path id="Vector_24" d="M255 80L259 80L259 87L255.007 87L255 80Z" fill="#FBC115" fill-opacity="0.6"/>
                    <path id="Vector_25" d="M249 80L252 80L252 87L249.005 87L249 80Z" fill="#FBC115" fill-opacity="0.5"/>
                    <path id="Vector_26" d="M242 80L246 80L246 87L242.007 87L242 80Z" fill="#FBC115" fill-opacity="0.4"/>
                    <path id="Vector_27" d="M236 80L239 80L239 87L236.005 87L236 80Z" fill="#FBC115" fill-opacity="0.3"/>
                    <path id="Vector_28" d="M229 80L233 80L233 87L229.007 87L229 80Z" fill="#FBC115" fill-opacity="0.2"/>
                    <path id="Vector_29" d="M223 80L226 80L226 87L223.005 87L223 80Z" fill="#FBC115" fill-opacity="0.1"/>
                    </g>
                    <rect id="logo-1 1" x="298" width="282" height="126" fill="url(#pattern-join-logo)"/>
                    <text id="This game is in alpha version and may contain bugs" fill="#848484" xml:space="preserve" style="white-space: pre; text-transform: uppercase;" font-family="Roboto" font-size="14" letter-spacing="0em"><tspan x="238" y="463.655">This Game Is In Alpha Version And May Contain Bugs</tspan></text>
                    <g id="Group 358">
                    <g id="join-button">
                    <g id="Group 135">
                    <path id="Rectangle 5" d="M366.25 346.896L366.25 318.25L512.75 318.25L512.75 348.566L507.768 353.75L373.104 353.75L366.25 346.896Z" fill="url(#paint8_linear_467_1287)" stroke="#FBC115" stroke-width="0.5"/>
                    </g>
                    <foreignObject x="360" y="313.736" height="50px" width="50px">
                        <b-button class="loader-fake-btn" :loading="matchLoader"></b-button>
                    </foreignObject>
                    <text id="Join match" fill="#FBC115" xml:space="preserve" style="white-space: pre" font-family="Anson" font-size="18" letter-spacing="0em"><tspan :x="matchLoader ? 400 : 403.236" y="338.736"> {{ matchLoader ? 'JOINING MATCH' : 'JOIN MATCH' }}</tspan></text>
                    </g>
                    <g id="Your friend challenges you in Station ZeroX: Encounter">
                    <text fill="#FBC115" xml:space="preserve" style="white-space: pre" font-family="Anson" font-size="31" letter-spacing="0em"><tspan x="293.566" y="269.148">Station ZeroX: Encounter</tspan></text>
                    <text fill="#A08021" xml:space="preserve" style="white-space: pre" font-family="Anson" font-size="24" letter-spacing="0em"><tspan x="303.73" y="238.148">Your Friend Challenges You In&#10;</tspan></text>
                    </g>
                    </g>
                    </g>
                    </g>
                    <defs>
                    <pattern id="pattern-join-bg" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlink:href="#image0_467_1287" transform="translate(-0.000427888) scale(0.000834046 0.00163132)"/>
                    </pattern>
                    <pattern id="pattern-join-logo" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlink:href="#image1_467_1287" transform="translate(0 -0.000139101) scale(0.000486855 0.00108963)"/>
                    </pattern>
                    <linearGradient id="paint0_linear_467_1287" x1="22.9138" y1="160.439" x2="22.9138" y2="281.434" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#252525" stop-opacity="0.82"/>
                    <stop offset="1" stop-color="#494949"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_467_1287" x1="22.9138" y1="292.566" x2="22.9138" y2="413.561" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#252525" stop-opacity="0.82"/>
                    <stop offset="1" stop-color="#494949"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_467_1287" x1="854.086" y1="160.439" x2="854.086" y2="281.434" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#252525" stop-opacity="0.82"/>
                    <stop offset="1" stop-color="#494949"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_467_1287" x1="854.086" y1="292.566" x2="854.086" y2="413.561" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#252525" stop-opacity="0.82"/>
                    <stop offset="1" stop-color="#494949"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_467_1287" x1="9.25426" y1="205.265" x2="9.25426" y2="368.778" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#252525" stop-opacity="0.82"/>
                    <stop offset="1" stop-color="#494949"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear_467_1287" x1="867.725" y1="205.222" x2="867.725" y2="368.841" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#252525" stop-opacity="0.82"/>
                    <stop offset="1" stop-color="#494949"/>
                    </linearGradient>
                    <linearGradient id="paint6_linear_467_1287" x1="438.5" y1="75" x2="438.5" y2="135.423" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#252525" stop-opacity="0.82"/>
                    <stop offset="1" stop-color="#494949"/>
                    </linearGradient>
                    <linearGradient id="paint7_linear_467_1287" x1="438.479" y1="438.577" x2="438.479" y2="498.979" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#252525" stop-opacity="0.82"/>
                    <stop offset="1" stop-color="#494949"/>
                    </linearGradient>
                    <linearGradient id="paint8_linear_467_1287" x1="419.5" y1="354" x2="419.5" y2="318" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FBC115" stop-opacity="0"/>
                    <stop offset="1" stop-color="#FBC115" stop-opacity="0.12"/>
                    </linearGradient>
                    <image id="image0_467_1287" data-name="battle-background.png" width="1200" height="613" href="https://res.cloudinary.com/station0x/image/upload/v1645077649/encouter/backgrounds/battle-background_1_3_aucezb.webp"/>
                    </defs>
                </svg>
            </div>
            <div v-if="matchLoader" class="loading-box">
                <b-button class="loader-fake-btn" :loading="true" ></b-button>
                <h1 class="loading-text">Setting up the battlefield, be ready!</h1>
                <!-- <h1 style="margin-top: 30px">{{ currentHint }}</h1> -->
            </div>
        </center>
    </div>
</template>

<script>
import axios from 'axios'
import { ethers } from 'ethers'

export default {
    data() {
        return {
            matchLoader: false,
            // hints: ['adssdsdsd', 'bdsdssdsd','ddsdssdsd','edsdsdssdsd'],
            // currentHint: ''
        }
    },
    methods:{
        async joinMatch() {
            try {
                this.matchLoader = true
                await axios.get("/api/match/joinMatch", {
                    params: {
                        signature: this.$store.state.signature,
                        inviteLink: this.$route.params.link
                    }
                })
            } finally {
                this.matchLoader = false
            }
        },
        async connectMetamask() {
            if(this.$store.state.address === null) {
                const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
                const signer = provider.getSigner()
                await provider.send("eth_requestAccounts", []);
                const accounts = await provider.listAccounts()
                const signature = await signer.signMessage("Station Labs Login")
                this.$store.dispatch('connect', {signature, address: await signer.getAddress()})
                if(this.$hj){
                    this.$hj('identify', this.$store.state.address, {})
                }
            }
            this.joinMatch()
        }
    },
    watch: {
        '$store.state.matchState' () {
            if(this.$store.state.matchId) {
                this.matchLoader = false
                this.$router.push('/lobby')
            }
        }
    },
    // created() {
    //     let time = 3
    //     let my_array = this.hints
    //     let self = this
    //     setInterval(function() {
    //         var ri = Math.floor(Math.random() * my_array.length);
    //         var result = my_array[ri];

    //         self.currentHint = result
    //         console.log(this.currentHint)
    //     }, time*1000)
    // }
}
</script>

<style scoped>
.join-wrapper {
    max-width: 930px;
    margin-top: 12.5vh;
}
</style>